import styled from '@emotion/styled';
import { navigate } from 'gatsby-link';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import SEO from '../components/seo';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import { LOG_IN_URL, SIGN_UP_CONFIRMATION } from '../constants';
import PublicLayout from '../layout/public-layout';
import { resetErrorAction } from '../state/actions/auth';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function SignUpConfirmation() {
  const dispatch = useDispatch();

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  return (
    <div>
      <SEO title="Sign Up" />
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            headingText={SIGN_UP_CONFIRMATION.DISPLAY_MESSAGE}
            paragraphText={SIGN_UP_CONFIRMATION.INSTRUCTION_TEXT}
            buttonText={SIGN_UP_CONFIRMATION.BUTTON_TEXT}
            onButtonClick={handleSignIn}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
